export default {
  CLOSE: "Закрыть",
  NEXT: "Дальше",
  PREV: "Назад",
  MODAL: "Вы можете закрыть данное окно, нажав клавишу ESC",
  ERROR: "Что-то пошло не так. Пожалуйста, повторите попытку позже",
  IMAGE_ERROR: "Изображение не найдено",
  ELEMENT_NOT_FOUND: "HTML элемент не найден",
  AJAX_NOT_FOUND: "Ошибка загрузки AJAX : Не найдено",
  AJAX_FORBIDDEN: "Ошибка загрузки AJAX : Запрещено",
  IFRAME_ERROR: "Ошибка загрузки страницы",
  TOGGLE_ZOOM: "Переключить уровень масштаба",
  TOGGLE_THUMBS: "Переключить эскиз",
  TOGGLE_SLIDESHOW: "Переключить презентацию",
  TOGGLE_FULLSCREEN: "Переключить режим полного экрана",
  DOWNLOAD: "Скачать",
};
